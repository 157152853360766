import React, {useState} from 'react';
import {graphql, Link} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layoutV2"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInnerV2";
import * as PATHS from "../../utils/paths";
import "../../utils/index"
import ContactForm from '../../components/ContactForm'
import {Button} from "@material-ui/core";
import {GridColQuarterContent, GridContainer, GridPageHero, SectionContainer} from "../../components/GridStuff";

const styles = theme => ({
    root: {},
    title: {
        fontSize: 86,
        lineSpacing: -1.76,
        lineHeight: 1.2,
        marginTop: 0,
        marginBottom: 12,
        hyphens: "manual",

        [theme.breakpoints.down('sm')]: {
            fontSize: 56,
        },
    },
    subtitle: {
        fontSize: 40,
        lineSpacing: -1.28,
        lineHeight: 1.2,
        fontWeight: 400,
        marginTop: 0,
        marginBottom: 24,
        hyphens: "manual",
        [theme.breakpoints.down('sm')]: {
            fontSize: 36,
        },
    },
    heroText: {
        fontFamily: "itext",
        fontSize: 20,
        lineHeight: 1.48,
        marginBottom: 24,
        hyphens: "manual",
    },
    smallText: {
        fontFamily: "itext",
        fontSize: 18,
        lineHeight: 1.24,
        marginBottom: 12,
        hyphens: "manual",
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: 16,
        // },
    },

    headImgWrap: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        aspectRatio: 1.618,
    },


    headImg: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
        },
    },
    spacer: {
        height: 96,
    },
    quoteText: {
        fontFamily: "itext",
        fontSize: 18,
        lineHeight: 1.24,
        marginBottom: 4,
        fontStyle: "italic",
    },
    quotePerson: {
        fontSize: 16,
        lineHeight: 1.2,
        color: "#0E0E0E",
        marginBottom: 0,
    },
    workshopList: {
        paddingLeft: "1.2em",
        lineHeight: 1,
        "& li": {
            marginBottom: 12,
            fontFamily: "itext",
            fontSize: 18,
            lineHeight: 1.24,
            hyphens: "manual",
        }
    }
});



const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={true}>
            <SEO title={"Workshop: Equitable Epidemiology"} location={{pathname: PATHS.WORKSHOPS_EQUITABLE_EPIDEMIOLOGY}}/>


            <LayoutInner>

                <GridPageHero
                    imgURLHorizontal={"https://3iap.com/cdn/images/3iap-eqep-head-hor-v03.png"}
                    imgURLVertical={"https://3iap.com/cdn/images/3iap-eqep-head-vert-v03.png"}
                >
                    <h1 className={classes.title}>Equitable Epidemiology</h1>
                    <h2 className={classes.subtitle}>Deep Dive Workshops for Public Health
                        Communicators</h2>
                    <p className={classes.heroText}>
                        Can risk ratios be risky? Can proportional mortality rates dis&shy;proportion&shy;ately
                        increase
                        mortality? Can adhering to communication conventions undermine adherence?

                        Epidemiology conventions, which may be perfectly suitable
                        published in The Lancet, require different considerations when produced for the
                        public.
                    </p>
                    {/*<p className={classes.heroText}>*/}
                    {/*    In these deep-dive workshops, we’ll seek to motivate an elevated duty-of-care for public*/}
                    {/*    health data communication, cover techniques for identifying prevalent risks in messaging,*/}
                    {/*    and propose alternative, evidence-backed chart choices for common use cases for visualizing*/}
                    {/*    population health outcomes.*/}
                    {/*</p>*/}
                    <p className={classes.heroText}>Please get in touch to book a time or a schedule a custom
                        training.</p>
                    <Link to={"#get-in-touch"} style={{textDecoration: "none"}}>
                        <Button variant={"contained"} color={"primary"} style={{width: "50%"}}>Get in touch</Button>
                    </Link>
                </GridPageHero>

                <div className={classes.spacer}/>


                <SectionContainer title={"Workshop Overview"}>
                    <p className={classes.heroText}>
                        Equitable visualizations support healthier populations.
                        Thoughtful data design can play an
                        active role in correcting health misbeliefs and harmful stereotypes, as well as supporting
                        impactful communication goals like risk perception, public support, and behavioral
                        adherence. </p>
                    <p className={classes.heroText}>On the other hand, conventional ways of visualizing group health
                        outcomes can be actively harmful.</p>
                    <p className={classes.heroText}>
                        In this workshop, we’ll learn how to visualize population health
                        to support health outcomes
                        that are more equitable for communities of focus, and more effective for the whole
                        population in general.</p>
                    <br/>

                    <GridContainer style={{rowGap: 0}}>


                        <GridColQuarterContent>
                            <p className={classes.quoteText}>"Throughout the rest of our conference, we constantly
                                heard people talking about how enlightening and thought-provoking they found your
                                talk and how they will change their trainings and practices because of it."</p>
                            <p className={classes.quotePerson}>Regional HIV Advocacy & Education Organizer</p>
                        </GridColQuarterContent>
                        <GridColQuarterContent>
                            <p className={classes.quoteText}>"Hearing about this continues to make me consciously
                                examine my choices and think about what I'm doing and showing. Thank you."</p>
                            <p className={classes.quotePerson}>Strategy & Performance Manager, Transforming
                                Aboriginal Outcomes, NSW Department of Communities and Justice</p>
                        </GridColQuarterContent>
                        <GridColQuarterContent>
                            <p className={classes.quoteText}>"Thank you. This was fantastic (and unsettling)."</p>
                            <p className={classes.quotePerson}>SFDPH Analyst</p>
                        </GridColQuarterContent>
                    </GridContainer>
                </SectionContainer>


                <div className={classes.spacer}/>
                <SectionContainer title={"Learning Objectives"}>


                    <p className={classes.heroText} style={{marginBottom: 48}}>After these workshops, health
                        communicators will:</p>


                    <GridContainer  style={{rowGap: 0}}>
                        <GridColQuarterContent>
                            <p className={classes.smallText} style={{marginBottom: 0}}>
                                1. Understand how data visualization can impact health communication goals like
                                perceived vulnerability, public support for health policies,
                                behavioral intent, and attributions to social determinants of health.</p>
                        </GridColQuarterContent>
                        <GridColQuarterContent>
                            <p className={classes.smallText} style={{marginBottom: 0}}>
                                2. Appreciate how popular
                                conventions for visualizing group outcomes can
                                reinforce harmful misbeliefs about population health,
                                and how these misbeliefs can actually undermine health outcomes for the
                                populations being visualized.</p>
                        </GridColQuarterContent>
                        <GridColQuarterContent>
                            <p className={classes.smallText} style={{marginBottom: 0}}>
                                3. Make alternative
                                chart choices to more effectively, equitably convey
                                population health outcomes.</p>
                        </GridColQuarterContent>
                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>

                <img style={{maxWidth: "100%"}}
                     src={"https://3iap.com/cdn/images/3iap-workshop-equitable-epidemiology-sample-slides-preview.png"}/>
                <figcaption>A random sample of slides.</figcaption>

                <div className={classes.spacer}/>


                <SectionContainer title={"Intended Audience"}>
                    <p className={classes.heroText}>The workshops are for health communicators involved in producing
                        public-facing
                        visualizations of population health outcomes, in particular:</p>

                    <GridContainer>
                        <GridColQuarterContent>
                            <ul className={classes.workshopList}>
                                <li>Public Health Analysts</li>
                                <li>Educators, Communicators, and Administrators working at municipal, state, or
                                    federal health agencies
                                </li>
                                <li>Advocacy groups using data to promote health equity</li>
                                <li>Data Journalists covering health policy</li>

                            </ul>
                        </GridColQuarterContent>
                    </GridContainer>


                </SectionContainer>
                <div className={classes.spacer}/>


                <SectionContainer title={"Format and Structure"}>
                    <p className={classes.heroText}>
                        Material can be adapted based on your groups’ needs and interests, to cover various themes.
                        Each session requires 60-90 minutes, including a presentation of the topics, supporting
                        example redesigns, questions and discussion, and group exercises.

                        Workshop case studies can also be customized to handle your specific data and use cases.
                    </p>
                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer title={"Pricing"}>
                    <p className={classes.heroText}>
                        Recommended rates are as follows, however pricing can be flexible to meet your organization's
                        needs.
                    </p>
                    <GridContainer>
                        <GridColQuarterContent>
                            <p className={classes.smallText}><b>Large Group Talk:</b> To motivate the topic and
                                introduce the concepts to the wider organization:</p>
                            <ul className={classes.workshopList}>
                                <li>Single 45 minute primer talk, plus two case studies and Q&A</li>
                                <li>$2,000 per 100 attendees</li>
                            </ul>
                        </GridColQuarterContent>
                        <GridColQuarterContent>
                            <p className={classes.smallText}><b>Interactive Workshops:</b> To support group
                                discussion and explore more targeted topics in greater depth:</p>
                            <ul className={classes.workshopList}>
                                <li>Two to four deep dive sessions, including interactive group exercises and
                                    discussions
                                </li>
                                <li>$59 per participant per session, for 10–30 participants</li>
                            </ul>
                        </GridColQuarterContent>

                    </GridContainer>


                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer title={""}>
                    <hr/>
                </SectionContainer>

                <div className={classes.spacer}/>

                <a id={"get-in-touch"}/>
                <SectionContainer title={"Next Steps"}>

                    <p className={classes.heroText}>Please get in touch to book a time or a schedule a custom
                        training.</p>

                    <ul className={classes.heroText}>
                        <li>Email <a href={"mailto:hi+workshops@3iap.com"}>hi+workshops@3iap.com</a><br/></li>
                        <li>Book a time on <a href={"https://calendly.com/3iap/intro-call"}>3iap's Calendly</a></li>
                        <li>Or use the form below to send a message</li>
                    </ul>

                    <ContactForm layoutComponent={({children}) => (<div>{children}</div>)}/>
                </SectionContainer>


            </LayoutInner>


        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
